import { api } from '.';
import { ANALYTICS_RESOURCE, RAW_DATA_RESOURCE, CLIENT_RESOURCE, REPORTS_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 * @param {string} clientId
 * @returns {Promise.<{data:AnalyticsReportList[], meta:EndpointMeta}>}
 */
export async function getAnalyticsRawDataReportList(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: null,
    [CLIENT_RESOURCE]: clientId,
    [RAW_DATA_RESOURCE]: null,
    [REPORTS_RESOURCE]: null,
  });
  params.addInclude(['author', 'offer', 'advertiser', 'campaign', 'publisher', 'country']);
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
