import { api } from '.';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
import getDateISOwithUTC from '@/utils/dateTime/getDateISOwithUTC';
/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @param {object} config this is only for breakdown services
 * @returns {Promise.<{data:Analytics[], meta:EndpointMeta}>}
 */
export async function getAnalytics(clientId, params = new QueryParamsBuilder(), config = { preventCancel: false }) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: null,
    [CLIENT_RESOURCE]: clientId,
  });
  params.addFilter('range_start', getDateISOwithUTC(params.removeFilter('range_start')));
  params.addFilter('range_end', getDateISOwithUTC(params.removeFilter('range_end')));
  const { data } = await api.get(partialUrl, params.build(), config);
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
