import { api } from '.';
import { ANALYTICS_RESOURCE, REPORT_RESOURCE, CLIENT_RESOURCE, RAW_DATA_RESOURCE } from '@/services/keys';

/**
 * @param {object} payload
 * @returns {Promise<{id:string, message: string}>}
 */
export async function createAnalyticsRowDataReport(clientId, payload) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: undefined,
    [CLIENT_RESOURCE]: clientId,
    [RAW_DATA_RESOURCE]: undefined,
    [REPORT_RESOURCE]: undefined,
  });
  const { data } = await api.create(partialUrl, payload);
  return { id: data.rawDataReport.id, message: data.message };
}
