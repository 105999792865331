import { api } from '.';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, RAW_DATA_RESOURCE, COUNT_BLOCKED_REASONS } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @param {object} config this is only for breakdown services
 * @returns {Promise.<{data:Analytics[], meta:EndpointMeta}>}
 */
export async function getBlockedReasons(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: null,
    [CLIENT_RESOURCE]: clientId,
    [RAW_DATA_RESOURCE]: null,
    [COUNT_BLOCKED_REASONS]: null,
  });
  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
