import { api } from '.';
import { createAnalyticsReport } from '@/services/shared/analytics/createAnalyticsReport';
import getDateISOwithUTC from '@/utils/dateTime/getDateISOwithUTC';

/**
 * @param {string} clientId
 * @param {object} config
 * @returns {Promise.<{id:string}>}
 */
export default function (clientId, config) {
  const { format, type, breakdown, subBreakdown, ...params } = config;
  params.filter.range_end = getDateISOwithUTC(params.filter.range_end);
  params.filter.range_start = getDateISOwithUTC(params.filter.range_start);
  const payload = {
    format,
    payload: { ...params },
    type,
    // TODO We tally to backend to delete these properties.
    breakdown,
    subBreakdown,
  };
  return createAnalyticsReport(api, clientId, payload);
}
