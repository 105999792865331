import getCurrentUTC from '@/utils/dateTime/getCurrentUTC';
import getDateUTC from '@/utils/dateTime/getDateUTC';

/**
 * @param {Date} date
 * @returns string
 */
export default function (date) {
  return getDateUTC(date).toISOString().slice(0, -1) + getCurrentUTC();
}
