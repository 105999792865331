import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import { ANALYTICS_RESOURCE, CLIENT_RESOURCE, EVENT_SESSION_ID, RAW_DATA } from '@/services/keys';
import { api } from '.';

/**
 *
 * @param {string} clientId
 * @param {string} eventSessionId
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:RawData[], meta:EndpointMeta}>}
 */
export async function getRawDataByEventSessionId(clientId, eventSessionId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [ANALYTICS_RESOURCE]: null,
    [CLIENT_RESOURCE]: clientId,
    [RAW_DATA]: null,
    [EVENT_SESSION_ID]: eventSessionId,
  });
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
