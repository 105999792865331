import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { getAnalytics } from './getAnalytics';

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:Analytics[], meta:EndpointMeta}>}
 */
export function getBreakdownAnalytics(clientId, params = new QueryParamsBuilder()) {
  return getAnalytics(clientId, params, { preventCancel: true });
}
